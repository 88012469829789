import { useCallback, useMemo, useState } from 'react'
import { isEmpty, isEqual, pick } from 'lodash'
import { useParams } from 'react-router-dom'

import { differenceInObjects } from '@acre/utils'
import {
  CaseStatus,
  GetCasePreferencesDocument,
  GetCaseVerificationsDocument,
  GetMortgageProductDocument,
  GetPropertyDocument,
  Mortgage,
  useCreateMortgageMutation,
  useDeleteMortgageMutation,
  useDeleteMortgagesMutation,
  useUpdateMortgageAndProductMutation,
} from '@acre/graphql'

import { useCaseContext } from '../../../contexts/CaseContext'
import { updateMortgageCacheDelete } from '../../../graphql/cache/mortgage'
import { MortgageFnArgs } from './useMutateMortgage.types'

export const useMutateMortgage = ({ caseId }: { caseId?: string }) => {
  const [mortgageToEdit, setMortgageToEdit] = useState<Mortgage>()
  const { details: caseDetails } = useCaseContext()
  const isLeadCase = caseDetails?.status === CaseStatus.Lead
  const { propertyId } = useParams<{ propertyId: string }>()

  const createAndUpdateMortgageRefetchQueries = useMemo(() => {
    if (isLeadCase) return []

    const queries = []

    if (caseId) {
      queries.push({
        query: GetCasePreferencesDocument,
        variables: { id: caseId },
      })
      queries.push({
        query: GetCaseVerificationsDocument,
        variables: { id: caseId },
      })
      queries.push({
        query: GetMortgageProductDocument,
        variables: { id: caseId },
      })
    }

    if (propertyId) {
      queries.push({
        query: GetPropertyDocument,
        variables: { id: propertyId },
      })
    }

    return queries
  }, [caseId, isLeadCase, propertyId])

  const [createMortgage, { loading: loadingCreateMortgage, data }] = useCreateMortgageMutation({
    refetchQueries: createAndUpdateMortgageRefetchQueries,
    awaitRefetchQueries: true,
  })

  const deleteMorgageRefetchQueries = propertyId
    ? [{ query: GetPropertyDocument, variables: { id: propertyId } }]
    : undefined

  const [deleteMortgage, { loading: loadingDeleteMortgage }] = useDeleteMortgageMutation({
    update: (cache, { data }) => {
      if (data) {
        return updateMortgageCacheDelete(cache, data)
      }
    },
    refetchQueries: deleteMorgageRefetchQueries,
    awaitRefetchQueries: true,
  })

  const [deleteMortgages, { loading: loadingDeleteMortgages }] = useDeleteMortgagesMutation({
    refetchQueries: deleteMorgageRefetchQueries,
    awaitRefetchQueries: true,
  })

  const [updateMortgageAndProduct, { loading: loadingUpdateMortgageAndProduct }] = useUpdateMortgageAndProductMutation()

  const handleUpdateMortgage = async (
    mortgage: MortgageFnArgs,
    mortgageId: string,
    mortgageInitialValues?: MortgageFnArgs,
  ) => {
    // Ensure that the mortgage product doesn't get updated when the mortgage is sourced (and not lender proposed)
    // as patching any fields on mortgage product should not be possible for a sourced product (all mortgage product fields
    // are disabled in the UI).
    const isSourcedMortgage =
      mortgage.mortgageInput.selected_mortgage_club_code !== '184' && // PRIMIS
      Boolean(mortgage.mortgageInput.raw_results_reference) &&
      !mortgage.mortgageInput.lender_proposed

    const mortgageInitialVals = mortgageInitialValues?.mortgageInput
    const mortgageProductInitialVals = mortgageInitialValues?.mortgageProductInput

    // to prevent unneccessary calls, we check diff between initial and new values to see which mortgages should be updated
    const shouldUpdateMortgageProduct = () => {
      if (isSourcedMortgage || isEmpty(mortgage.mortgageProductInput)) {
        return false
      }
      const hasMortgageProductDifferences = !isEqual(
        pick(mortgageProductInitialVals, Object.keys(mortgage.mortgageProductInput)),
        mortgage.mortgageProductInput,
      )
      return hasMortgageProductDifferences
    }

    const _shouldUpdateMortgageProduct = shouldUpdateMortgageProduct()
    const productDetailsInput = _shouldUpdateMortgageProduct
      ? {
          ...mortgage?.mortgageProductInput,
          // Ensure that net_proc_fee_percentage is rounded to the nearest whole number
          net_proc_fee_percentage: mortgage?.mortgageProductInput?.net_proc_fee_percentage
            ? Math.round(mortgage?.mortgageProductInput?.net_proc_fee_percentage)
            : mortgage?.mortgageProductInput?.net_proc_fee_percentage,
        }
      : undefined

    return await updateMortgageAndProduct({
      variables: {
        input: {
          mortgage_id: mortgageId,
          mortgage: differenceInObjects(mortgage.mortgageInput, mortgageInitialVals || {}),
          product_details: productDetailsInput,
        },
      },
      refetchQueries: createAndUpdateMortgageRefetchQueries,
      awaitRefetchQueries: true,
    })
  }

  const handleCreateMortgage = useCallback(
    async (caseId: string, mortgage: MortgageFnArgs) => {
      return await createMortgage({
        variables: {
          caseId,
          ...mortgage,
        },
      })
    },
    [createMortgage],
  )

  const handleDeleteMortgage = useCallback(
    async (caseId: string, mortgageId: string, isPropertyPortfolio?: boolean) => {
      return await deleteMortgage({
        variables: { caseId, mortgageId, isPropertyPortfolio },
      })
    },
    [deleteMortgage],
  )

  const handleDeleteMortgages = useCallback(
    async (caseId: string, mortgages: Mortgage[], isPropertyPortfolio?: boolean) => {
      if (!isEmpty(mortgages)) {
        const mortgageIds = mortgages.map((mortgage) => mortgage.id)
        return await deleteMortgages({
          variables: { caseId, mortgageIds, isPropertyPortfolio },
        })
      }
    },
    [deleteMortgages],
  )

  const loading = useMemo(
    () => loadingCreateMortgage || loadingDeleteMortgage || loadingUpdateMortgageAndProduct || loadingDeleteMortgages,
    [loadingCreateMortgage, loadingDeleteMortgage, loadingDeleteMortgages, loadingUpdateMortgageAndProduct],
  )

  return {
    createMortgage,
    handleCreateMortgage,
    handleDeleteMortgage,
    handleUpdateMortgage,
    handleDeleteMortgages,
    loading,
    mortgageToEdit,
    setMortgageToEdit,
    createdMortgage: data?.createMortgage,
  }
}
