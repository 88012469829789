/**
 * Converts an object into URLSearchParams 
 * Handles arrays by adding multiple entries with same key ((e.g., case_status=[PRE_RECOMMENDATION,LEAD] becomes case_status=PRE_RECOMMENDATION&types=LEAD))
 */
export const objectToUrlParams = (input: Record<string, any>): URLSearchParams => {
    const params = new URLSearchParams();
    Object.entries(input).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(item => params.append(key, String(item)));
        } else if (value !== null) {
            params.append(key, String(value));
        }
    });
    return params;
};
