import React from 'react'
import { alpha, Radio, RadioProps, styled, unstable_composeClasses, useRadioGroup } from '@mui/material'
import classnames from 'classnames'

import { ExtendField, SimpleFieldProps, SimpleFieldWrapperProps } from '../Field/Field.types'
import SimpleFieldWrapper from '../SimpleFieldWrapper/SimpleFieldWrapper'
import { getRadioFieldUtilityClass } from './radioFieldClasses'

export type RadioFieldVariant = 'standard' | 'outlined'

export interface RadioFieldProps extends Omit<SimpleFieldProps, 'name'>, ExtendField<RadioProps> {
  variant?: RadioFieldVariant
}

const RadioFieldRoot = styled(SimpleFieldWrapper, {
  name: 'RadioField',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
  shouldForwardProp: (prop) => prop !== 'layout' && prop !== 'selected',
})<SimpleFieldWrapperProps & { selected?: boolean; variant?: RadioFieldVariant }>(({ theme, variant, selected }) => ({
  ...(variant === 'outlined' && {
    border: `1px solid ${theme.colours.baseLight}`,
    borderRadius: theme.shape.borderRadius,
    paddingRight: theme.spacing(1),
    flex: 1,
    marginRight: 0,
    marginLeft: 0,
    width: '100%',

    '&:hover:not(.Mui-disabled)': {
      borderColor: theme.palette.primary.main,
    },

    [`& .${getRadioFieldUtilityClass('radio')}`]: {
      paddingTop: theme.spacing(7 / 8),
      paddingBottom: theme.spacing(7 / 8),
      paddingLeft: theme.spacing(7 / 8),
    },

    ...(selected && {
      '&.Mui-disabled': {
        backgroundColor: alpha(theme.colours.baseLight, 0.1),
      },

      '&:not(.Mui-disabled)': {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.colours.primary, 0.1),
      },
    }),
  }),
}))

const RadioFieldRadio = styled(Radio, {
  name: 'RadioField',
  slot: 'Radio',
  overridesResolver: (props, styles) => styles.radio,
})(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))

const Icon = styled('span', {
  name: 'RadioField',
  slot: 'Icon',
  overridesResolver: (props, styles) => styles.icon,
})(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),

  borderRadius: '100%',

  borderColor: theme.colours.baseLight,
  borderStyle: 'solid',
  borderWidth: '2px',
  boxShadow: 'none',

  'input:disabled ~ &': {
    borderColor: theme.colours.baseLight,
    backgroundColor: theme.colours.baseExtraLight,
    boxShadow: `inset 0px 0px 0px 2px ${theme.colours.baseExtraLight}`,
  },
}))

const CheckedIcon = styled(Icon, {
  name: 'RadioField',
  slot: 'CheckedIcon',
  overridesResolver: (props, styles) => styles.checkedIcon,
})(({ theme }) => ({
  transition: 'background-color 0.1s ease-in-out',

  borderColor: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.main,
  boxShadow: `inset 0px 0px 0px 2px ${theme.colours.foreground}`,

  'input:disabled ~ &': {
    color: theme.colours.baseLight,
    borderColor: theme.colours.baseLight,
    backgroundColor: theme.colours.baseLight,
  },
}))

const useUtilityClasses = (ownerState: Partial<RadioFieldProps>) => {
  const slots = {
    root: ['root'],
    radio: ['radio'],
    icon: ['icon'],
    checkedIcon: ['checkedIcon'],
  }

  return unstable_composeClasses(slots, getRadioFieldUtilityClass, ownerState.classes)
}

const _RadioField = (props: RadioFieldProps) => {
  const {
    className,
    classes,
    WrapperProps,
    LabelProps,
    label,
    ['data-testid']: dataTestId,
    error,
    size,
    variant = 'standard',

    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes })

  const radioGroup = useRadioGroup()
  const selected = String(radioGroup?.value) === String(rootProps.value)

  const labelId = label ? `${radioGroup?.name}-label` : ''

  return (
    <RadioFieldRoot
      {...WrapperProps}
      error={Boolean(error)}
      data-testid={dataTestId}
      className={classnames(slotClasses.root, WrapperProps?.className)}
      variant={variant}
      selected={selected}
      label={label}
      LabelProps={{ ...LabelProps, id: labelId }}
      size={size}
      control={
        <RadioFieldRadio
          {...rootProps}
          disableRipple
          className={classnames(slotClasses.radio, className)}
          icon={<Icon className={slotClasses.icon} />}
          checkedIcon={<CheckedIcon className={slotClasses.checkedIcon} />}
        />
      }
    />
  )
}

const RadioField = React.memo(_RadioField) as typeof _RadioField

export default RadioField
