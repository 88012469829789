import { GraphQLError } from 'graphql'
import { isEmpty } from 'lodash'
import { v4 as generateUniqueId } from 'uuid'

import envConfig from '@acre/config'

import {
  CaseVersion,
  CreateMortgageInput,
  CreateMortgageWithProduct,
  CreateMortgageWithProductInput,
  Maybe,
  Mortgage,
  MortgageInput,
  MortgageProductCode,
  MortgageReason,
  MortgageStatus,
  PropertyVersion,
  TermUnit,
  UpdateMortgagesInput,
  UpdateMortgageWithProductInput,
} from '../generated/resolvers'
import { mortgageLoader, mortgageVersionLoader } from '../loaders/mortgage'
import request from '../requesters/default'
import {
  getEndDate,
  getPropertyValue,
  getProposedStartDate,
  getTermDateForRemortgage,
  GraphqlException,
} from '../resolvers/util'
import {
  CdmCreateMortgageResponse,
  CdmDeleteMortgageCaseResponse,
  CdmGetMortgageRequest,
  CdmGetMortgageResponse,
  CdmUpdateMortgageResponse,
} from '../service/luther/model'
import { UUID } from '../types'
import { getFirstUsedKey, processLoaderResults, shouldUseLoader, spreadParameters } from '../utils/dataloader'
import { mapToLutherMortgageProduct, SourcingMortgageState } from '../utils/schemaMapping/mapToLutherMortgageProduct'
import { formatMortgage } from '../utils/schemaMapping/mortgage'
import { CaseLoader, fetchCase, updateCase } from './case'
import { fetchProperty } from './property'

// Please note we never want to batch by `filter_by_client_ids
// because when multiplt client ids are passed into that param
// the api would only return the mortgages where both clients exist on that mortgage
// and would not return the mortgages that clients have individually
const batchKeys = new Set<keyof CdmGetMortgageRequest>(['mortgage_ids', 'filter_ext_ids'])

export const fetchMortgage = async (params: CdmGetMortgageRequest) => {
  const mortgage = await mortgageLoader.load(params)

  if (mortgage instanceof GraphQLError) {
    throw mortgage
  }

  return mortgage ? formatMortgage(mortgage) : null
}

export const fetchMortgages = async (params: CdmGetMortgageRequest) => {
  if (shouldUseLoader(params, batchKeys)) {
    const batchKey = getFirstUsedKey(params, [...batchKeys])

    const batchParams = params[batchKey]

    if (Array.isArray(batchParams) && batchParams.length > 1) {
      const mortgagesFromLoader = await mortgageLoader.loadMany(spreadParameters(params, batchKey))

      const clean = processLoaderResults(mortgagesFromLoader)

      return clean.map(formatMortgage)
    }

    const mortgage = await mortgageLoader.load(params)

    if (mortgage instanceof GraphQLError) {
      throw mortgage
    }

    if (Array.isArray(mortgage)) {
      return mortgage.map(formatMortgage)
    }

    return mortgage ? [formatMortgage(mortgage)] : null
  }

  const response = await request.get<CdmGetMortgageResponse>('/mortgage', {
    params: { mortgage_details: true, ...params },
  })

  return response.data.mortgages?.map(formatMortgage) ?? []
}

export const fetchMortgageByVersion = async (params: { mortgage_id: string; version: number }) => {
  const mortgage = await mortgageVersionLoader.load(params)

  if (mortgage instanceof Error) {
    console.error(mortgage)
    return null
  }

  return mortgage ? formatMortgage(mortgage) : null
}

export const createMortgage = async (input: MortgageInput) => {
  const response = await request.post<CdmCreateMortgageResponse>('/mortgage', input)

  input.case_ids?.forEach((id) => id && mortgageLoader.clear({ filter_case_id: id }))
  input.property_secured_ids?.forEach((id) => mortgageLoader.clear({ filter_property_secured_id: id }))

  return response.data.mortgage ? formatMortgage(response.data.mortgage) : null
}

export const updateMortgage = async (mortgage_id: UUID, input: MortgageInput) => {
  const response = await request.patch<CdmUpdateMortgageResponse>(`/mortgage/${mortgage_id}`, input)

  mortgageLoader.clearAll()

  return response.data.mortgage ? formatMortgage(response.data.mortgage) : null
}

export const deleteMortgage = async (mortgageId: string, caseId: string) => {
  const response = await request.delete<CdmDeleteMortgageCaseResponse>(`/mortgage/${mortgageId}/case/${caseId}`)

  mortgageLoader.clearAll()

  return response.data
}

export const getProductListByProductCodeAndLender = async (
  isBtl: boolean,
  productCode?: string | null,
  lender?: string | null,
) => {
  const lenderQuery = lender ? `&fq=lender:${lender}` : ''
  const response = await request.get<{ response: { docs: Array<MortgageProductCode> } }>(
    `/acre/local-sourcing/source?is_btl=${isBtl}&network=pms&q=doctype:mortgageproduct%20AND%20productCode:${productCode}*${lenderQuery}&fl=productCode,id,name,lender&rows=20`,
    {
      baseURL: envConfig.API1_URL,
    },
  )

  return response?.data?.response?.docs || []
}

export const sourceProductBySourcingProductId = async (
  isBtl: boolean,
  productId?: string | null,
  loanAmount?: Maybe<string>,
  valuationAmount?: Maybe<string>,
  isCurrent?: Maybe<boolean>,
) => {
  const response = await request.get<{ response: { docs: Array<SourcingMortgageState> } }>(
    `/acre/local-sourcing/source?&is_btl=${isBtl}&network=pms&q=id:${productId}&fl=*,[child%20limit=100]`,
    {
      baseURL: envConfig.API1_URL,
    },
  )

  if (!response?.data?.response?.docs?.[0]) {
    throw new GraphqlException(
      'Product details could not be retrieved by product code. Please enter the details manually.',
    )
  }

  return mapToLutherMortgageProduct(response.data.response.docs[0], loanAmount, valuationAmount, isCurrent)
}

export const updateMortgageHelper = async (id: string, input: MortgageInput) => {
  let mortgage: Mortgage | null = null
  const term = input.term || 0
  const termUnit = input.term_unit
  const status = input.status
  const startDate = input.mortgage_start_date

  if (status === MortgageStatus.StatusLenderProposed) {
    input.lender_proposed = true
  }

  //only update the end date if the status is current or lender proposed as these are the only manual edit case.
  if (status === MortgageStatus.StatusCurrent || status === MortgageStatus.StatusLenderProposed) {
    // This is a transition under mortgage_end_date is calculated by BE.
    if (startDate && term && termUnit) {
      input.mortgage_end_date = getEndDate(startDate, Number(term), termUnit as TermUnit)
    }

    mortgage = await updateMortgage(id, input)

    return {
      ...mortgage,
      mortgage_id: String(mortgage?.id),
      id: String(mortgage?.id),
    }
  }

  //if mortgage status is repaid or selected the start and end date does not need to be calculated
  //It would have already been calculated at the create stage.
  mortgage = await updateMortgage(id, input)

  return mortgage
}

export const createMortgageHelper = async (input: CreateMortgageInput) => {
  const { caseId, mortgageInput, mortgageProductInput } = input

  // Create the mortgage and mortgage product
  // If a caseId is provided, it will link the mortgage to the case
  const { mortgage, mortgage_id, product_details, product_id } = await createMortgageWithProductAndLinkToCase({
    case_id: caseId,
    mortgage: mortgageInput,
    product_details: mortgageProductInput,
  })

  return {
    ...mortgage,
    id: mortgage_id,
    mortgage_product_id: product_id,
    mortgage_product: product_details,
  } as Mortgage
}

export const shouldLinkToTarget = (
  { status, property_secured_ids }: MortgageInput,
  caseDetails: CaseVersion | null,
) => {
  const propertyTargetId = caseDetails?.details.preference_target_property || ''

  const hasTargetProperty = !!propertyTargetId
  const isProposed = status === MortgageStatus.StatusLenderProposed || status === MortgageStatus.StatusProposed
  const isLinkedToTarget = hasTargetProperty && !!(property_secured_ids || []).includes(propertyTargetId)

  return isProposed && hasTargetProperty && !isLinkedToTarget
}

export const shouldLinkToRelatedSale = (
  { status, property_secured_ids }: MortgageInput,
  caseDetails: CaseVersion | null,
) => {
  const propertyRelatedSaleId = caseDetails?.details.preference_related_property_sale || ''

  const hasTargetProperty = !!propertyRelatedSaleId
  const isCurrent = status === MortgageStatus.StatusCurrent
  const isLinkedToRelatedSale = hasTargetProperty && !!(property_secured_ids || []).includes(propertyRelatedSaleId)

  return isCurrent && hasTargetProperty && !isLinkedToRelatedSale
}

export const updateMortgages = async (input: UpdateMortgagesInput[]) => {
  let mortgages: Mortgage[] = []

  if (!input?.length) return mortgages

  // Currently BE chokes if calls happen async which could leave the entities in an inconsistent state
  // Someday when BE is optimised this needs to be promise.all
  for (let mortgageInput of input) {
    if (!mortgageInput.mortgageId) return

    const { mortgage, mortgage_id, product_id, product_details } = await updateMortgageAndProduct(
      mortgageInput.mortgageId,
      {
        mortgage_id: mortgageInput.mortgageId,
        mortgage: mortgageInput.mortgageInput,
        product_details: mortgageInput.mortgageProductInput,
      },
    )

    if (mortgage) {
      mortgages.push({
        ...mortgage,
        id: mortgage_id,
        mortgage_product_id: product_id,
        mortgage_product: product_details,
      } as Mortgage)
    }
  }

  return mortgages
}

export const createMortgageWithProductAndLinkToCase = async (input: CreateMortgageWithProductInput) => {
  // set defaults for term and status
  const status = input.mortgage?.status || MortgageStatus.InvalidStatus
  const termUnit = input?.mortgage?.term_unit || TermUnit.InvalidTermUnit

  let term = input?.mortgage?.term || 0
  let property: Maybe<PropertyVersion> = null
  let mortgageCase: Maybe<CaseVersion> = null
  let preferenceTargetProperty: Maybe<string> = null

  const caseId = input.case_id
  const mortgage = input.mortgage || {}

  if (caseId) {
    mortgageCase = await fetchCase(caseId)
    // check if the case is remortgage and the sourced product is status proposed
    if (
      (mortgageCase?.details.preference_mortgage_reason === MortgageReason.ReasonRemortgage ||
        mortgageCase?.details.preference_mortgage_reason === MortgageReason.ReasonBtlRemortgage) &&
      !(mortgageCase?.details.preference_term && mortgageCase?.details.preference_term > 0)
    ) {
      // if existing mortgage exists get the current mortgage
      let newMortgageTerm = getTermDateForRemortgage(
        input.product_details?.early_repayment_charge_periods || [],
        term,
        input.product_details?.initial_rate_period || '',
      )

      const updateCaseResponse = await updateCase({ preference_term: newMortgageTerm }, caseId)
      if (updateCaseResponse) {
        CaseLoader.clear(caseId).prime(caseId, updateCaseResponse)
      }
    }
  }

  // if property exists on the case, we'll fetch it so that we can get its value
  preferenceTargetProperty = mortgageCase?.details.preference_target_property || ''

  if (mortgage?.property_secured_ids && mortgage?.property_secured_ids[0]) {
    property = await fetchProperty(mortgage?.property_secured_ids[0])
  } else if (preferenceTargetProperty) {
    property = await fetchProperty(preferenceTargetProperty)
  }

  const startDate =
    status === MortgageStatus.StatusCurrent ? mortgage?.mortgage_start_date : getProposedStartDate(status)

  mortgage.mortgage_start_date = startDate
  mortgage.mortgage_end_date = getEndDate(startDate, term, termUnit)

  const targetPropertyValue = mortgageCase?.details.preference_target_property_value || ''
  mortgage.property_value = getPropertyValue(property, status, targetPropertyValue)

  // if the mortgage we're trying to add has a status of 'current', then we need to link it to the case's target property
  // if it has a status if 'proposed' or 'lender proposed', we need to link it to the related sale property
  if (shouldLinkToTarget(mortgage, mortgageCase)) {
    mortgage?.property_secured_ids?.push(mortgageCase?.details.preference_target_property || '')
  }

  if (shouldLinkToRelatedSale(mortgage, mortgageCase)) {
    mortgage?.property_secured_ids?.push(mortgageCase?.details.preference_related_property_sale || '')
  }

  if (mortgage?.status === MortgageStatus.StatusLenderProposed) {
    mortgage.lender_proposed = true
  }

  const response = await request.post('/mortgage_with_product', {
    case_id: caseId,
    mortgage: mortgage,
    product_details: !isEmpty(input.product_details)
      ? {
          ...input.product_details,
          // Generate product code if there isn't one already
          product_code: input.product_details?.product_code || generateUniqueId(),
        }
      : {},
  })

  if (!response.data.mortgage) {
    throw new GraphQLError('No mortgage returned from mutation', {
      extensions: {
        status: 500,
      },
    })
  }

  return { ...response.data, mortgage: formatMortgage(response.data.mortgage) } as CreateMortgageWithProduct
}

export const updateMortgageAndProduct = async (mortgage_id: string, input: UpdateMortgageWithProductInput) => {
  const response = await request.patch(`/mortgage_with_product/${mortgage_id}`, {
    mortgage: input.mortgage,
    product_details: !isEmpty(input.product_details)
      ? {
          ...input.product_details,
          // Generate product code if there isn't one already
          product_code: input.product_details?.product_code || generateUniqueId(),
        }
      : {},
  })

  return { ...response.data, mortgage: formatMortgage(response.data.mortgage) }
}
